import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getForm } from '../services/forms';
import { Formik, Form, Field, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
  Box
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { DynamicForm } from '../cms/types';

enum FormStatus {
  DRAFTING = 'drafting',
  SUBMITTING = 'submitting',
  SUCCESS = 'success',
  ERROR = 'error'
}


function Forms() {
  const { uid } = useParams();
  const [formConfig, setFormConfig] = useState<DynamicForm | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [theme, setTheme] = useState<any | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.DRAFTING);
  const [formValues, setFormValues] = useState<Record<string, any>>({}); // Store form values
  const replacePlaceholders = (template: string) => {
    const data = template.replace(/{{(.*?)}}/g, (_, key) => formValues[key.trim()] || '');
    return data;
  };
  useEffect(() => {
    document.title = 'GSD Form';

    const fetchForm = async () => {
      try {
        const data = await getForm(uid || '');
        if (data) {
          setFormConfig(data);
          const {
            primaryColor = '#c01519', // Default primary color
            secondaryColor = '#f50057', // Default secondary color
            errorColor = '#ff4d4d' // Default error color
          } = data;

          setTheme(
            createTheme({
              typography: {
                fontFamily: 'Montserrat',
                fontSize: 11.4,
              },
              palette: {
                primary: {
                  main: primaryColor,
                },
                secondary: {
                  main: secondaryColor,
                },
                error: {
                  main: errorColor,
                },
              },
            })
          );
        } else {
          setError('Form not found.');
        }
      } catch (err) {
        console.error('Error fetching form:', err);
        setError('An error occurred while fetching the form.');
      }
      setIsLoading(false);
    };

    if (uid) {
      fetchForm();
    }
  }, [uid]);

  if (error) {
    return (
      <Typography color="error" style={{ fontFamily: 'Montserrat' }}>
        Error: {error}
      </Typography>
    );
  }

  if (isLoading || formStatus === FormStatus.SUBMITTING) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const validationSchema = Yup.object().shape(
    (formConfig?.form_data || []).reduce((acc: any, field: any) => {
      let validator;
      if (field.field_type === 'checkbox') {
        validator = Yup.array();
        if (field.required) {
          validator = validator.min(1, 'At least one option must be selected');
        }
      } else if (field.field_type === 'radio' || field.field_type === 'text') {
        validator = Yup.string();
        if (field.required) {
          validator = validator.required('This field is required');
        }
      } else if (field.field_type === 'email') {
        validator = Yup.string().email('Invalid email address');
        if (field.required) {
          validator = validator.required('This field is required');
        }
      } else if (field.field_type === 'phone') {
        validator = Yup.string().matches(/^[0-9+]*$/, 'Invalid phone number');
        if (field.required) {
          validator = validator.required('This field is required');
        }
      } else {
        validator = Yup.string(); // Default to string
      }

      acc[field.field_name] = validator;
      return acc;
    }, {})
  );

  const handleSubmit = async (values: Record<string, any>) => {
    setFormStatus(FormStatus.SUBMITTING);
    setFormValues(values); // Store form values
    const payload = {
      formId: uid,
      ...values,
    };
    
    setIsLoading(true);
    try {
      await axios.post('https://clauber.ngrok.dev/form', payload);
      console.log('looks like we are good to go!');
      setFormStatus(FormStatus.SUCCESS);
    } catch (error) {
      console.error('Submission error:', error);
      setFormStatus(FormStatus.ERROR);
      setConfirmation('Failed to submit form. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box height="100vh" p={3}>
        { formStatus === FormStatus.SUCCESS ? (
          <Box mt="auto" px={5} display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Typography
            variant="h6"
              dangerouslySetInnerHTML={{
                __html: replacePlaceholders(formConfig?.submitSuccessMessage || ''),
              }}
            />
          </Box>
        ) : formStatus === FormStatus.ERROR ? <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography color="error">{formConfig?.submitErrorMessage}</Typography></Box>
          : formStatus === FormStatus.DRAFTING ? <>
        <Typography variant="h4" gutterBottom color="primary">
          {formConfig?.title}
        </Typography>
              <Typography variant="body1" gutterBottom style={{ marginBottom: '24px' }}>
                {formConfig?.description || ''}
              </Typography>

              <Formik
                initialValues={(formConfig?.form_data || []).reduce((acc: Record<string, any>, field: any) => {
                  acc[field.field_name] = field.field_type === 'checkbox' ? [] : '';
                  return acc;
                }, {})}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }: { errors: FormikErrors<Record<string, any>>, touched: FormikTouched<Record<string, any>> }) => (
                  <Form>
                    {formConfig?.form_data.map((field: any) => (
                      <div key={field.field_name} style={{ marginBottom: '24px' }}>
                        {field.field_type === 'radio' ? (
                          <FormControl component="fieldset" error={Boolean(errors[field.field_name] && touched[field.field_name])}>
                            <FormLabel component="legend">
                              {field.field_label} {field.required && '*'}
                            </FormLabel>
                            <RadioGroup name={field.field_name}>
                              {field.options.map((option: string) => (
                                <Field key={option} name={field.field_name} type="radio" value={option}>
                                  {({ field }: any) => (
                                    <FormControlLabel control={<Radio {...field} />} label={option} />
                                  )}
                                </Field>
                              ))}
                            </RadioGroup>
                            {errors[field.field_name] && touched[field.field_name] && typeof errors[field.field_name] === 'string' && (
                              <Typography color="error">{String(errors[field.field_name])}</Typography>
                            )}
                          </FormControl>
                        ) : field.field_type === 'checkbox' ? (
                          <FormControl component="fieldset" error={Boolean(errors[field.field_name] && touched[field.field_name])}>
                            <FormLabel component="legend">
                              {field.field_label} {field.required && '*'}
                            </FormLabel>
                            <FormGroup>
                              {field.options.map((option: string) => (
                                <Field name={field.field_name} type="checkbox" value={option} as={Checkbox} key={option}>
                                  {({ field }: any) => (
                                    <FormControlLabel control={<Checkbox {...field} />} label={option} />
                                  )}
                                </Field>
                              ))}
                            </FormGroup>
                            {errors[field.field_name] && touched[field.field_name] && typeof errors[field.field_name] === 'string' && (
                              <Typography color="error">{String(errors[field.field_name])}</Typography>
                            )}
                          </FormControl>
                        ) : (
                          <Field name={field.field_name}>
                            {({ field: formikField }: any) => (
                              <TextField
                                {...formikField}
                                required={field.required || false}
                                label={field.field_label}
                                type="text"
                                variant="outlined"
                                fullWidth
                                error={Boolean(errors[field.field_name] && touched[field.field_name])}
                                helperText={touched[field.field_name] && typeof errors[field.field_name] === 'string' && String(errors[field.field_name])}
                                onInput={(e) => {
                                  const target = e.target as HTMLInputElement;
                                  if (field.field_type === 'phone') {
                                    target.value = target.value.replace(/[^0-9+]/g, '');
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}
                      </div>
                    ))}
                    <Button type="submit" variant="contained" color="primary">
                      {formConfig?.submitButtonString}
                    </Button>
                  </Form>
                )}
              </Formik>

              {confirmation && (
                <Typography variant="body2" color="secondary" style={{ marginTop: '24px' }}>
                  {confirmation}
                </Typography>
              )}
            </> : ""}
      </Box>
    </ThemeProvider>
  );
}

export default Forms;
