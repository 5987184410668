// ./services/forms.service.ts
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { DynamicForm } from '../cms/types';

const FORM_DATA_COLLECTION = 'forms';
const FORM_RESPONSE_COLLECTION = 'form_responses';
export const getForm = async (uid: string): Promise<DynamicForm> => {
  try {
    // Step 1: Fetch the form document
    const formRef = doc(db, FORM_DATA_COLLECTION, uid);
    const formSnap = await getDoc(formRef);

    if (!formSnap.exists()) {
      throw new Error('Form not found');
    }

    const formData = formSnap.data() as DynamicForm;

    // Step 2: Fetch references in form_data
    if (formData && Array.isArray(formData.form_data)) {
      const referencePromises = formData.form_data.map(async (fieldRef: any) => {
        if (fieldRef && fieldRef._key && fieldRef._key.path) {
          const segments = fieldRef._key.path.segments;
          const collectionName = segments[segments.length - 2];
          const docId = segments[segments.length - 1];
          const refDoc = await getDoc(doc(db, collectionName, docId));
          return refDoc.exists() ? refDoc.data() : null;
        }
        return null;
      });

      const referencesData = await Promise.all(referencePromises);
      formData.form_data = referencesData.filter(ref => ref !== null);
    }

    return formData;
  } catch (error) {
    console.error('Error fetching form with references:', error);
    throw error;
  }
};

export const submitToFireCMS = async (formData: Record<string, any>) => {
  try {
    const docRef = await addDoc(collection(db, FORM_RESPONSE_COLLECTION), formData);
    return docRef.id; 
  } catch (error) {
    console.error('Error adding document to FireCMS:', error);
    throw error;
  }
};
