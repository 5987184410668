import React from 'react';
import './NewPlayPartyScheduleTermModal.css';

interface NewPlayPartyScheduleTermModalProps {
  price: number | null;
  isOpen: boolean;
  onClose: () => void;
}

const NewPlayPartyScheduleTermModal: React.FC<
  NewPlayPartyScheduleTermModalProps
> = ({ price, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Terms and Conditions</h2>
          <button onClick={onClose} className="close-button">
            &times;
          </button>
        </div>
        <div className="modal-body">
          <h3>Payment and Cancellation Policy</h3>

          <p>
            By booking a party with New Play, you acknowledge and agree to the
            following terms:
          </p>

          <h4>Payment</h4>
          <ul>
            <li>
              The full amount of ${(price as number / 100).toFixed(2)} will be charged to your credit card
              immediately upon booking.
            </li>
            <li>This payment secures your party date and time slot.</li>
          </ul>

          <h4>Cancellation Policy</h4>
          <ul>
            <li>
              Cancellations made more than 72 hours before the party will
              receive a full refund.
            </li>
            <li>
              Cancellations made less than 72 hours before the party will only
              receive a 50% refund ($87.50).
            </li>
            <li>No-shows will not receive a refund.</li>
          </ul>

          <h4>Rescheduling</h4>
          <ul>
            <li>
              To reschedule your party, please contact us at least 72 hours in
              advance.
            </li>
            <li>
              Rescheduling requests made less than 72 hours before the party may
              be subject to the cancellation policy.
            </li>
          </ul>

          <h4>Contact Information</h4>
          <p>
            For cancellations, rescheduling, or any questions, please contact
            us:
          </p>
          <ul>
            <li>Phone: (435) 216-0706</li>
            <li>Email: newplayut@gmail.com</li>
          </ul>

          <p className="terms-note">
            Thank you for understanding our policy. We implement these terms to
            ensure fair scheduling and availability for all our customers.
          </p>
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="close-modal-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPlayPartyScheduleTermModal; 