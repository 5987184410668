import React, { useState, useCallback, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BookingPage.css";
import TermsModal from "../../components/NewPlayPartyScheduleTermModal";
import { getCalendarEvents, type BookedEvent, type CalendarConfig, requestTimeSlot, confirmAppointment, stopHeartbeat } from '../../services/rest';
import { toast } from 'react-toastify';
import { BookingCalendar } from './Calendar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersonalDetails } from './PersonalDetails';
import { 
  validateEmail, 
  validatePhone, 
  validateName, 
  formatPhoneNumber,
  ERROR_MESSAGES 
} from './utils';
import { Payment } from './Payment';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface TimeSlot {
  time: string;
  available: boolean;
}

// Add validation state
interface ValidationErrors {
  name: string;
  email: string;
  phone: string;
}



const BookingPage = () => {
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState<Value>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });
  const [bookingState, setBookingState] = useState({
    paymentStatus: 'idle',
    error: '',
    isSuccess: false,
  });
  const [acceptedToc, setAcceptedToc] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({
    name: '',
    email: '',
    phone: ''
  });
  const [bookedEvents, setBookedEvents] = useState<BookedEvent[]>([]);
  const [calendarConfig, setCalendarConfig] = useState<CalendarConfig>({
    timezone: '',
    availableDays: {},
    price: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Function to handle going back
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleTimeSelect = (timeSlot: string) => {
    setSelectedTime(timeSlot);
  };

  const handleNext = async () => {
    if (!selectedDate || !selectedTime) return;

    setIsProcessing(true);
    try {
      const selectedDateObj = selectedDate as Date;
      const [startTime, endTime] = selectedTime.split(' - ')
        .map(time => {
          const [hour, minute, period] = time.match(/(\d+):(\d+) (AM|PM)/)?.slice(1) || [];
          let hours = parseInt(hour);
          if (period === 'PM' && hours !== 12) hours += 12;
          if (period === 'AM' && hours === 12) hours = 0;
          return `${hours.toString().padStart(2, '0')}:${minute}`;
        });

      const formattedDate = selectedDateObj.toISOString().split('T')[0];

      const response = await requestTimeSlot({
        date: formattedDate,
        startTime,
        endTime,
        ...(appointmentId && { appointmentId })
      });

      if (response.status === 'success' && response.appointmentId) {
        setAppointmentId(response.appointmentId);
        setStep(2);
      } else {
        toast.error(
          <div>
            <strong>Time Slot No Longer Available</strong>
            <br />
            The selected time slot ({selectedTime}) has been booked by someone else.
            <br />
            Please select another time.
          </div>,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        
        // Refresh calendar data to get updated availability
        const updatedData = await getCalendarEvents();
        setBookedEvents(updatedData.events);
        setCalendarConfig(updatedData.config);
        
        // Reset selection
        setSelectedTime(null);
      }
    } catch (error) {
      console.error('Error requesting time slot:', error);
      toast.error(
        <div>
          <strong>Booking Error</strong>
          <br />
          Unable to reserve this time slot. Please try again or select another time.
        </div>,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setIsProcessing(false);
    }
  };


  
  // Modified handleInputChange to only update values, not validate
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      const digits = value.replace(/\D/g, '');
      if (digits.length <= 10) {
        const formattedPhone = formatPhoneNumber(value);
        setUserInfo(prev => ({
          ...prev,
          [name]: formattedPhone
        }));
      }
    } else {
      setUserInfo(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // New blur handler for validation
  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    switch (name) {
      case 'name':
        setErrors(prev => ({
          ...prev,
          name: value && !validateName(value) ? ERROR_MESSAGES.name : ''
        }));
        break;
      
      case 'email':
        setErrors(prev => ({
          ...prev,
          email: value && !validateEmail(value) ? ERROR_MESSAGES.email : ''
        }));
        break;
      
      case 'phone':
        setErrors(prev => ({
          ...prev,
          phone: value && !validatePhone(value) ? ERROR_MESSAGES.phone : ''
        }));
        break;
    }
  };

  // Updated payment success handler
  const handlePaymentSuccess = async (token: any, buyer: any) => {
    if(!calendarConfig.price === null) {
      toast.error('No price found. Please try booking again.');
      return;
    }
    if (!appointmentId) {
      toast.error('No appointment found. Please try booking again.');
      return;
    }

    setBookingState(prev => ({ ...prev, paymentStatus: 'processing' }));

    try {
      // Format the confirmation data
      const confirmationData = {
        appointmentId,
        fullName: userInfo.name,
        email: userInfo.email,
        phoneNumber: userInfo.phone,
        notes: userInfo.notes,
        token,
        buyer,
        paymentData: {
          squareOrderId: {token,buyer}, // Assuming Square returns this
          amount: calendarConfig.price as number
        }
      };

      const response = await confirmAppointment(confirmationData);

      if (response.status === 'success') {
        stopHeartbeat();
        setBookingState(prev => ({ 
          ...prev, 
          paymentStatus: 'success',
          isSuccess: true 
        }));
        toast.success('Booking confirmed successfully!');
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      console.error('Confirmation failed:', error);
      stopHeartbeat();
      
      // Extract error message from API response if available
      const errorMessage = error.response?.data?.message || 
        (error instanceof Error ? error.message : 'Failed to confirm booking. Please try again later.');

      setBookingState(prev => ({ 
        ...prev, 
        paymentStatus: 'error',
        error: errorMessage
      }));
      
      toast.error(
        <div>
          <strong>Booking Failed</strong>
          <br />
          {errorMessage}
        </div>
      );
    }
  };

  // Validate all fields before allowing to proceed to payment
  const handleProceedToPayment = () => {
    const newErrors = {
      name: !validateName(userInfo.name) ? ERROR_MESSAGES.name : '',
      email: !validateEmail(userInfo.email) ? ERROR_MESSAGES.email : '',
      phone: !validatePhone(userInfo.phone) ? ERROR_MESSAGES.phone : '',
    };

    setErrors(newErrors);

    if (!Object.values(newErrors).some(error => error)) {
      setStep(3);
    } else {
      const firstErrorField = document.querySelector('.is-invalid');
      firstErrorField?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // Add this handler
  const handlePaymentError = (error: string) => {
    setBookingState(prev => ({ 
      ...prev, 
      paymentStatus: 'error',
      error: error 
    }));
    
    // Scroll to the error message
    const errorElement = document.querySelector('.payment-error-message');
    errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const data = await getCalendarEvents();
        setBookedEvents(data.events);
        setCalendarConfig(data.config);
      } catch (error) {
        console.error('Failed to fetch calendar data:', error);
        // Optionally set an error state here to show to the user
      } finally {
        setIsLoading(false);
      }
    };

    fetchCalendarData();
  }, []);

  return (
    <div className="square-booking-container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {bookingState.error && (
        <div className="error-banner">
          {bookingState.error}
          <button 
            onClick={() => setBookingState(prev => ({ ...prev, error: '' }))}
            className="error-close"
          >
            ×
          </button>
        </div>
      )}

      <div className="booking-progress">
        <div className={`progress-step ${step >= 1 ? "active" : ""}`}>
          Schedule
        </div>
        <div className={`progress-step ${step >= 2 ? "active" : ""}`}>
          Details
        </div>
        <div className={`progress-step ${step >= 3 ? "active" : ""}`}>
          Payment
        </div>
      </div>

      <div className="service-info" >
        <h2>Book a Party</h2>
        {calendarConfig.price && (
          <p className="service-price">
            ${(calendarConfig.price / 100).toFixed(2)}
          </p>
        )}
      </div>

      {step > 1 && (
        <button onClick={handleBack} className="back-button">
          <span>←</span> Back
        </button>
      )}

      {step === 1 && (
        <BookingCalendar
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          bookedEvents={bookedEvents}
          calendarConfig={calendarConfig}
          isLoading={isLoading}
          isProcessing={isProcessing}
          onDateChange={setSelectedDate}
          onTimeSelect={handleTimeSelect}
          onNext={handleNext}
        />
      )}

      {step === 2 && (
        <PersonalDetails
          userInfo={userInfo}
          errors={errors}
          isProcessing={isProcessing}
          onInputChange={handleInputChange}
          onInputBlur={handleInputBlur}
          onNext={handleProceedToPayment}
        />
      )}

      {step === 3 && (
        <Payment
          price={calendarConfig.price}
          userInfo={userInfo}
          selectedDate={selectedDate as Date}
          selectedTime={selectedTime!}
          acceptedToc={acceptedToc}
          setAcceptedToc={setAcceptedToc}
          onOpenTerms={() => setIsTermsModalOpen(true)}
          bookingState={bookingState as { paymentStatus: "error" | "idle" | "success" | "processing"; error: string; isSuccess: boolean; }}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
        />
      )}

      <TermsModal
        price={calendarConfig.price}
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
      />
    </div>
  );
};

export default BookingPage;
