import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CMS } from './cms/CMS';
import Forms from './pages/Forms';
import BookingPage from './pages/BookingPage';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/cms/*" element={<CMS />} />
        <Route path="/form/:uid" element={<Forms />} />
        <Route path="/newplay-book-a-party" element={<BookingPage />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;