// src/Tiptap.tsx
import { Card } from "@firecms/ui";
import TipTapMenuBar from './MenuBar'
import {
  useEditor,
  EditorContent,
  FloatingMenu,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import './Editor.scss'

// define your extension array
const extensions = [StarterKit];

const content = "<p>Hello World!</p>";

const Tiptap = () => {
	
  const editor = useEditor({
    extensions,
    content,
  });

  return (
    <>
      <Card className="card m-5 tiptap-editor">
        <div className="tiptap-editor">
        <TipTapMenuBar editor={editor}/>
        <EditorContent  editor={editor} />
        </div>
			
				
        {/* <FloatingMenu editor={editor}>This is the floating menu</FloatingMenu> */}
        {/* <BubbleMenu editor={editor}>This is the bubble menu</BubbleMenu> */}
      </Card>
    </>
  );
};

export default Tiptap;
