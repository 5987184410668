import { buildCollection, buildProperty } from "@firecms/cloud";

export const formFieldsCollection = buildCollection({
    id: "form_fields",
    path: "form_fields",
    name: "Form Fields",
    singularName: "Form Field",
    group: "Forms",
    icon: "text_fields",
    description: "Collection of fields that can be used in dynamic forms",
    properties: {
        field_name: buildProperty({
            dataType: "string",
            name: "Field Name",
            validation: {
                required: true,
                requiredMessage: "Field Name is required"
            }
        }),
        field_label: buildProperty({
            dataType: "string",
            name: "Field Label",
            validation: {
                required: true,
                requiredMessage: "Field Label is required"
            }
        }),
        field_type: buildProperty({
            dataType: "string",
            name: "Field Type",
            enumValues: {
                text: "Text",
                long_text: "Long Text",
                email: "Email",
                phone: "Phone Number",
                date: "Date",
                boolean: "Boolean",
                radio: "Radio Buttons",
                checkbox: "Checkbox"
            },
            validation: {
                required: true,
                requiredMessage: "Field Type is required"
            }
        }),
        required: buildProperty({
            dataType: "boolean",
            name: "Required",
            defaultValue: false
        }),
        options: buildProperty({
            dataType: "array",
            name: "Options",
            of: {
                dataType: "string"
            },
            // Conditional validation logic should be handled in your application logic
        }),
        validation_rules: buildProperty({
            dataType: "map",
            name: "Validation Rules", 
            properties: {
                minLength: buildProperty({
                    dataType: "number",
                    name: "Minimum Length"
                }),
                maxLength: buildProperty({
                    dataType: "number",
                    name: "Maximum Length"
                }),
                pattern: buildProperty({
                    dataType: "string",
                    name: "Pattern"
                })
            }
        })
    }
});