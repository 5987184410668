import { buildCollection, buildProperty } from "@firecms/cloud";
import { DynamicForm, SubmissionConfig } from "../../types";


export const formsCollection = buildCollection<DynamicForm>({
    id: "forms",
    path: "forms",
    name: "Forms",
    singularName: "Form",
    group: "Forms",
    icon: "assignment",
    description: "Collection of dynamic forms for user input",
    textSearchEnabled: true,
    properties: {
        title: buildProperty({
            dataType: "string",
            name: "Title",
            validation: {
                required: true,
                requiredMessage: "Title is required"
            }
        }),
        description: buildProperty({
            dataType: "string",
            name: "Description",
            markdown: true
        }),
        primaryColor: buildProperty({
            dataType: "string",
            name: "Primary Color",
        }),
        secondaryColor: buildProperty({
            dataType: "string",
            name: "Secondary Color",
        }),
        errorColor: buildProperty({
            dataType: "string",
            name: "Error Color",
        }),
        form_data: buildProperty({
            dataType: "array",
            name: "Form Data",
            of: {
                dataType: "reference",
                path: "form_fields" // Reference to form fields collection
            }
        }),
        dynamic_conditions: buildProperty({
            dataType: "array",
            name: "Dynamic Conditions",
            of: {
                dataType: "map",
                properties: {
                    field: buildProperty({
                        dataType: "string",
                        name: "Field Name"
                    }),
                    condition: buildProperty({
                        dataType: "string",
                        name: "Condition"
                    }),
                    value: buildProperty({
                        dataType: "string",
                        name: "Value"
                    })
                }
            }
        }),
        pages: buildProperty({
            dataType: "array",
            name: "Pages",
            of: {
                dataType: "map",
                properties: {
                    title: buildProperty({
                        dataType: "string",
                        name: "Page Title"
                    }),
                    fields: buildProperty({
                        dataType: "array",
                        name: "Fields",
                        of: {
                            dataType: "string" // Reference to field names or IDs
                        }
                    })
                }
            }
        }),
        submission_config:buildProperty({
            dataType: "array",
            name: "Form REST Submits",
            of: {
                dataType: "reference",
                path: "form_rest_submits" // Reference to form fields collection
            }
        }),
            submitButtonString: buildProperty({
            dataType: "string",
            name: "Submit Button String",
            validation: {
                required: true,
                requiredMessage: "Submit Button String is required"
            }
        }),
        submitSuccessMessage: buildProperty({
            dataType: "string",
            name: "Success Message",
            markdown: true,
            validation: {
                required: true,
                requiredMessage: "How will people know it worked?"
            }
        }),
        submitErrorMessage: buildProperty({
            dataType: "string",
            name: "Error Message",
            markdown: true,
            validation: {
                required: true,
                requiredMessage: "How will people know it failed?"
            }
        }),
      }
  });